<template>
  <div class="page-scrollable">
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-if="!isLoading">
      <div class="part">
        <div class="row">
          <div class="route-title">{{ $t('PrivacyPolicy') }}</div>
          <div v-if="!isEditing1" class="icon" v-html="EditItem()" @click="toggleEdit(1)"></div>
          <button class="icon" @click="cancelEdit(1)" v-if="isEditing1">الغاء</button>
          <button class="icon" @click="sendDataNormal(this.data[0].id,this.text1)" style="background-color: darkgreen;color: white"
                  v-if="isEditing1">حفظ
          </button>
        </div>
        <div class="editable-container">
          <p v-if="!isEditing1">{{ text1 }}</p>
          <textarea v-else class="custom-input" v-model="this.text1" @keyup.enter="saveText(1)"
                    @blur="cancelEdit(1)"></textarea>
        </div>
      </div>
      <div class="part">
        <div class="row">
          <div class="route-title">{{ $t('PaymentPolicy') }}</div>
          <div v-if="!isEditing2" class="icon" v-html="EditItem()" @click="toggleEdit(2)"></div>
          <div>
            <button class="icon" @click="cancelEdit(2)" v-if="isEditing2">الغاء</button>
            <button class="icon" @click="sendDataNormal(this.data[1].id,this.text2)" style="background-color: darkgreen;color: white"
                    v-if="isEditing2">حفظ
            </button>

          </div>

        </div>
        <div class="editable-container">
          <p v-if="!isEditing2">{{ text2 }}</p>
          <textarea v-else class="custom-input" v-model="this.text2" @keyup.enter="saveText(2)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>
      <div class="part">

        <div class="row">
          <div class="route-title">{{ $t('ContractPolicy') }}</div>
          <div v-if="!isEditing3" class="icon" v-html="EditItem()" @click="toggleEdit(3)"></div>
          <div>
            <button class="icon" @click="cancelEdit(3)" v-if="isEditing3">الغاء</button>
            <button class="icon" @click="sendDataNormal(this.data[2].id,this.text3)" style="background-color: darkgreen;color: white"
                    v-if="isEditing3">حفظ
            </button>

          </div>
        </div>
        <div class="editable-container">
          <p v-if="!isEditing3">{{ text3 }}</p>
          <textarea v-else class="custom-input" v-model="this.text3" @keyup.enter="saveText(3)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>

      <div class="part">

        <div class="row">
          <div class="route-title">{{ $t('bankAccount') }}</div>
          <div v-if="!isEditing5" class="icon" v-html="EditItem()" @click="toggleEdit(5)"></div>
          <div>
            <button class="icon" @click="cancelEdit(5)" v-if="isEditing5">الغاء</button>
            <button class="icon" @click="sendDataNormal(this.data[4].id,this.text5)" style="background-color: darkgreen;color: white"
                    v-if="isEditing5">حفظ
            </button>

          </div>
        </div>
        <div class="editable-container">
          <p v-if="!isEditing5">{{ text5 }}</p>
          <textarea v-else class="custom-input" v-model="this.text5" @keyup.enter="saveText(3)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>

      <div class="part">

        <div class="row">
          <div class="route-title">{{ $t('whoAreWe') }}</div>
          <div v-if="!isEditing4" class="icon" v-html="EditItem()" @click="toggleEdit(4)"></div>
          <div>
            <button class="icon" @click="cancelEdit(4)" v-if="isEditing4">الغاء</button>
            <button class="icon" @click="sendDataNormal(this.data[3].id,this.text4)" style="background-color: darkgreen;color: white"
                    v-if="isEditing4">حفظ
            </button>

          </div>
        </div>
        <div class="editable-container">
          <p v-if="!isEditing4">{{ text4 }}</p>
          <textarea v-else class="custom-input" v-model="this.text4" @keyup.enter="saveText(4)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>
      <div class="part">

        <div class="row">
          <div class="route-title">سياسة الاستخدام</div>
          <div v-if="!isEditing6" class="icon" v-html="EditItem()" @click="toggleEdit(6)"></div>
          <div>
            <button class="icon" @click="cancelEdit(6)" v-if="isEditing6">الغاء</button>
            <button class="icon" @click="sendDataNormal(this.data[5].id,this.text6)" style="background-color: darkgreen;color: white"
                    v-if="isEditing6">حفظ
            </button>

          </div>
        </div>
        <div class="editable-container">
          <p v-if="!isEditing6">{{ text6 }}</p>
          <textarea v-else class="custom-input" v-model="this.text6" @keyup.enter="saveText(6)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>
      <div class="part">

        <div class="row">
          <div class="route-title">روابط الفيديوهات التعليمية</div>
        </div>

        <div class="video-links">
          <div class="add-video-container">
            <input v-model="newVideoLink" placeholder="ادخل رابط فيديو" class="video-input"/>
            <button @click="addVideoLink" class="add-button">اضافة</button>
          </div>

          <ul class="video-list">
            <li v-for="(link, index) in videoLinks" :key="index" class="video-item">
              {{ link }}
              <button @click="removeVideoLink(index)" class="remove-button">حذف</button>
            </li>
          </ul>

          <div v-if="editingVideoIndex !== null" class="edit-video-container">
            <input v-model="editedVideoLink" placeholder="Edit video link" class="video-input"/>
            <button @click="sendDataList" class="save-button">حفظ</button>
            <button @click="cancelEditVideoLink" class="cancel-button">الغاء</button>
          </div>
        </div>

      </div>
      <!--      <div class="row2">-->
      <!--        <div class="select-title">-->
      <!--          <label for="selectItem"> {{ $t('PlatformCommission') }}</label>-->
      <!--        </div>-->
      <!--        <input id="selectItem" v-model="commission">-->
      <!--      </div>-->
<!--      <div class="image-container">-->
<!--        <div class="image" @click="sendData">-->
<!--          <span class="button-text">{{ $t("Save") }}</span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {EditIcon, EditItem} from "@/util/constants";
import PublicPoliciesViewModel from "@/features/public_policies/viewmodels/PublicPoliciesViewModel";

export default {
  name: "PolicityContent",
  created() {
    this.getItems()
  },
  methods: {
    async getItems() {
      try {

        const policiesData = await PublicPoliciesViewModel.getAllPolicies();
        this.data = policiesData
        this.text1 = policiesData[0].content[0];
        this.text2 = policiesData[1].content[0];
        this.text3 = policiesData[2].content[0];
        this.text4 = policiesData[3].content[0];
        this.text5 = policiesData[4].content[0];
        this.text6 = policiesData[5].content[0];
        this.videoLinks = policiesData[6].content;

        this.commission = policiesData.commission;
        this.isLoading = false

      } catch (error) {
        this.isLoading = false

        console.error('Error fetching policies:', error);
      }
    },
    EditItem() {
      return EditItem
    },
    EditIcon() {
      return EditIcon
    },
    startEdit() {
      this.isEditing = true;
      this.editedText = this.text;
    },

    //here
    toggleEdit(index) {
      if (index === 1) {
        this.isEditing1 = !this.isEditing1;
        this.editedText1 = this.text1;

      } else if (index === 2) {
        this.isEditing2 = !this.isEditing2;
        this.editedText2 = this.text2;

      } else if (index === 3) {
        this.isEditing3 = !this.isEditing3;
        this.editedText3 = this.text3;

      } else if (index === 4) {
        this.isEditing4 = !this.isEditing4;
        this.editedText4 = this.text4;

      } else if (index === 5) {
        this.isEditing5 = !this.isEditing5;
        this.editedText5 = this.text5;

      } else if (index === 6) {
        this.isEditing6 = !this.isEditing6;
        this.editedText6 = this.text6;

      }

    },
    async sendDataNormal(number, value) {
      this.isLoading = true
      try {


        const response = await PublicPoliciesViewModel.updateAllPolicies(value, number);
        this.getItems()
        this.isEditing1 = false
        this.isEditing2 = false
        this.isEditing3 = false
        this.isEditing4 = false
        this.isEditing5 = false
        this.isEditing6 = false

        this.isLoading = false
        console.log(response);
      } catch (error) {
        this.isLoading = false

        console.error('Error sending data:', error);
      }
    },
    async sendDataList() {
      this.isLoading = true
      try {


        const response = await PublicPoliciesViewModel.updateAllPolicies2( this.videoLinks);
        this.getItems()
        this.isEditing1 = false
        this.isEditing2 = false
        this.isEditing3 = false
        this.isEditing4 = false
        this.isEditing5 = false
        this.isEditing6 = false

        this.isLoading = false
        console.log(response);
      } catch (error) {
        this.isLoading = false

        console.error('Error sending data:', error);
      }
    },
    saveText(index) {
      if (index === 1) {
        this.text1 = this.editedText1;
        this.isEditing1 = false;
      } else if (index === 2) {
        this.text2 = this.editedText2;
        this.isEditing2 = false;
      } else if (index === 3) {
        this.text3 = this.editedText3;
        this.isEditing3 = false;
      } else if (index === 4) {
        this.text4 = this.editedText4;
        this.isEditing4 = false;
      } else if (index === 5) {
        this.text5 = this.editedText5;
        this.isEditing5 = false;
      } else if (index === 6) {
        this.text6 = this.editedText6;
        this.isEditing6 = false;
      }
    },
    addVideoLink() {
      if (this.newVideoLink) {
        this.videoLinks.push(this.newVideoLink);
        this.newVideoLink = '';
        this.sendDataList()
      }
    },
    editVideoLink(index) {
      this.editingVideoIndex = index;
      this.editedVideoLink = this.videoLinks[index];

    },
    saveEditedVideoLink() {
      if (this.editingVideoIndex !== null && this.editedVideoLink) {
        this.videoLinks[this.editingVideoIndex] = this.editedVideoLink;
        this.cancelEditVideoLink();
      }
    },
    removeVideoLink(index) {
      this.videoLinks.splice(index, 1);
      this.sendDataList()
    },
    cancelEditVideoLink() {
      this.editingVideoIndex = null;
      this.editedVideoLink = '';
    },
    cancelEdit(index) {
      if (index === 1) {
        this.isEditing1 = false
      } else if (index === 2) {
        this.isEditing2 = false
      } else if (index === 3) {
        this.isEditing3 = false
      } else if (index === 4) {
        this.isEditing4 = false
      } else if (index === 5) {
        this.isEditing5 = false
      } else if (index === 6) {
        this.isEditing6 = false
      }


    },
  },

  data() {
    return {
      isLoading: true,
      editedText1: "",
      editedText2: "",
      editedText3: "",
      editedText4: "",
      editedText5: "",
      editedText6: "",
      commission: "",
      isEditing1: false,
      isEditing2: false,
      isEditing3: false,
      isEditing4: false,
      isEditing7: false,
      isEditing5: false,
      isEditing6: false,
      text3: '',
      videoLinks: [],
      data: [],
      newVideoLink: '',
      editingVideoIndex: null,
      editedVideoLink: '',
      text1: '',
      text2: '',
      text4: '',
      text5: '',
      text6: '',
      categories: [
        {id: 1, name: "Category 1", image: "Category 1"},
        {id: 2, name: "Category 2", image: "Category 1"},
        {id: 3, name: "Category 3", image: "Category 1"},
        // Add more categories as needed
      ],
    };
  },
};
</script>
<style scoped src="../styles/PublicPolicies.css">

</style>