<template>
  <div class="card">
    <h4>{{ title }}</h4>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Card',
  props: {
    title: String,
    description: String,
  },
};
</script>

<style scoped>
.card {
  padding: 16px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
  width: 160px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s; /* لتنعيم الانتقال */
}

p {
  color: #FFCC00;
  font-weight: bold;
  font-size: 14px
}

.card:hover {

  background-color: #FFCC00; /* لون الخلفية عند التمرير */
  color: black; /* لون النص عند التمرير */
}

.card:hover p {
  color: white; /* تغيير لون النص في الفقرة عند التمرير */
}
</style>
