<template>

  <div class="sidebar">
    <div @click="closePopUp" class="icon" v-html="closeIcon"></div>

    <div class="image-container">
    </div>
    <div class="title">
      Logo
    </div>
    <div class="menu-items">
      <router-link
          v-for="item in menuItems"
          :key="item.route"
          :to="item.route"
          class="side-btn"
          :class="{ 'active': isChildRouteActive(item) }"
          @click="navigateToRoute(item.route)">
        <div class="link-container">
          <div v-html="item.icon" class="icon-container"></div>
          {{ $t(item.label) }}

        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  Statistics,
  IntermediateStore,
  Products,
  Categories,
  PublicPolicies,
  Notifications,
  Messages, Logout, CloseIcon, Problems, Orders
} from "@/util/constants";
import MainViewModel from "@/features/main/viewmodels/MainViewModel";

export default {
  name: "SideBar",
  data() {
    return {
      closeIcon: CloseIcon,
      isSidebarOpen: true,
      menuItems: [

        {
          label: "Statistics", route: "MainStatistic", icon: Statistics,
          children: [
            {route: "MainStatistic"},
            {route: "UserDetails"},
          ]
        },
        {
          label: "IntermediateStores", route: "StoreResultsBody", icon: IntermediateStore, children: [
            {route: "IntermediateStoreDetails"},
            {route: "StoreResultsBody"},
            {route: "VerifyStore"},
            {route: "AddStore"},
            {route: "SortBody"},
            {route: "FilterBody"},
            {route: "FilterPage"},
            {route: "ShowReport"},
          ]
        },
        {
          label: "Products", route: "ProductsResults", icon: Products, children: [
            {route: "Products"},
            {route: "ProductsResults"},
            {route: "ProductsFilterPage"},
            {route: "ProductsDetails"},
            {route: "AddProduct"},
            {route: "AddRegion"},
            {route: "RegionContent"},
            {route: "EditRegion"},
            {route: "AddProductInfo"},
            {route: "SortProductsBody"},
            {route: "FilterProductsBody"},


          ]
        },
        {
          label: "Categories", route: "CategoriesData", icon: Categories, children: [
            {route: "Categories"},
            {route: "CategoriesData"},
            {route: "AddCategory"},
            {route: "AddSubCategory"},
            {route: "AddSubSubCategory"},
            {route: "SubCategoriesData"},
            {route: "SubCategoriesDetails"},
            {route: "AddBanner"},
            {route: "EditBanner"},
            {route: "EditSubSubCategory"},
            {route: "SubSubCategoriesDetails"},


          ]
        },

        // {
        //   label: "Book", route: "ReservationsResults", icon: Book, children: [
        //     {route: "Reservations"},
        //     {route: "ReservationsResults"},
        //     {route: "ReservationDetails"},
        //   ]
        // },
        {
          label: "orders", route: "OrdersPageCon", icon: Orders, children: [
            {route: "Orders"},
            {route: "OrdersResutls"},
            {route: "OrdersDetails"},
            {route: "OrdersPageCon"},
          ]
        },

        {
          label: "Problems", route: "ProblemsContent", icon: Problems, children: [
            {route: "Problems"},
            {route: "ProblemsContent"},
            {route: "AddProblem"},
            {route: "EditProblem"},


          ]
        },
        {
          label: "PublicPolicies", route: "PolicityContent", icon: PublicPolicies, children: [
            {route: "PublicPolicies"},
            {route: "PolicityContent"},

          ]
        },
        {
          label: "Notifications", route: "Notifications", icon: Notifications, children: [
            {route: "Notifications"},
          ]
        },
        {
          label: "Messages", route: "Messages", icon: Messages, children: [
            {route: "Messages"},
          ]
        },
        {
          label: "Logout", route: "Logout", icon: Logout, children: []
        },

      ],

    };
  },
  methods: {
    isChildRouteActive(item) {
      if (item.children && item.children.length > 0) {
        return item.children.some(child => child.route === this.$route.name);
      }
      return false;
    },
    closePopUp() {
      this.$emit('close-popup');

    },
    async navigateToRoute(route) {
      this.$emit('close-popup');

      if (route === 'Logout' && route !== this.$route.name) {
        this.disableBackButton();

        this.$router.replace({name: 'Login'});

        try {
          await MainViewModel.logout();

          this.isLoading = false;
        } catch (error) {
          console.error("Error fetching statistics:", error.message);
          this.isLoading = false;
        }

      } else if (route !== 'Logout' && (route !== this.$route.name || route === 'StoreResultsBody' || route === 'MainStatistic' || route === 'ReservationsResults' || route === 'ProductsResults' || route === 'CategoriesData' || route === 'PolicityContent' || route === 'Notifications' || route === 'Messages' || route === 'ProblemsContent' || route === 'OrdersResutls')) {

        console.log('nnnnnnnnnnnnnnnn')

        this.$router.push({name: route});
      }


    },
    disableBackButton() {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    },
  },

  beforeRouteEnter(to, from, next) {
    if (to.name === 'Login') {
      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        history.go(1);
      };
    }
    next();
  }

};
</script>

<style scoped src="../styles/SideBar.css">
</style>
