<template>
  <div>

    <table style="margin: 20px">
      <thead>
      <tr>
        <th>#</th>
        <th>المستخدم</th>
        <th>المنتج</th>
        <th>وصف المشكلة</th>
        <th>نوع المشكلة</th>
        <th>التاريخ</th>
        <th>تفاصيل المنتج</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(transaction,index) in transactions" :key="transaction.id">
        <td>{{ index+1 }}</td>
        <td >{{ transaction.user.first_name }}{{' '}}{{ transaction.user.last_name }}</td>

        <td >{{ transaction.product.code }}</td>
        <td >{{ transaction.description }}</td>

        <td >{{ transaction.type.name }}</td>

        <td>{{ new Date(transaction.created_at).toLocaleString() }}</td>
        <td>
          <div v-html="moreIcon()" style="cursor: pointer" @click="goToOrderDetails(transaction.product.id)"></div>
        </td>
      </tr>
      </tbody>
    </table>

    <div style="margin-top: 10px">
      <nav aria-label="...">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="prevPage">السابق</a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page">
            <a class="page-link" href="#" @click.prevent="currentPage = page">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="nextPage">التالي</a>
          </li>
        </ul>
      </nav>

    </div>
  </div>
</template>

<script>
import OrdersViewModel from "@/features/orders/viewmodels/OrdersViewModel";
import {moreIcon} from "@/util/constants";

export default {
  name: "ProblemsProductTable",

  data() {
    return {
      currentPage: 1,
      itemsPerPage: 5,

      transactions: [],
      selectedOperationType: "",
      selectedStatus: "pending",
    };
  },
  created() {
    this.getAllWalletOrders()
  },

  methods: {
    moreIcon() {
      return moreIcon
    },
    async getAllWalletOrders() {

      try {
        this.isLoading = true
        const data = await OrdersViewModel.getProblemsProductsOrders()
        this.transactions = data.data
        this.currentPage = data.current_page;
        this.totalPages = Math.ceil(data.total / 2);
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching user details:", error.message);
        this.isLoading = false;

      }
    },
    goToOrderDetails(id) {
      this.$router.push({name: 'ProductsDetails', params: {id: id},});

    },

    filterData() {
      this.getAllWalletOrders()
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  computed: {
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.transactions.slice(start, start + this.itemsPerPage);
    },
    // حساب العدد الإجمالي للصفحات
    totalPages() {
      return Math.ceil(this.transactions.length / this.itemsPerPage);
    },

  },
};
</script>

<style scoped>
/* إضافة بعض الأنماط لتحسين العرض */
table {
  width: 97%;
  border-collapse: collapse;
}

th {
  background-color: #FFCC00;
  color: white;
}

th, td {
  border: 1px solid grey;
  padding: 8px;
  text-align: center;

}

.pagination {
  display: flex; /* Use flexbox for horizontal layout */
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove padding */
  align-items: center;
  text-align: center;
  justify-content: center;
}

.page-item {
  margin: 0 5px; /* Add some margin between items */
}

.page-item {
  margin: 0 5px;
}

.page-link {
  text-decoration: none;
  padding: 8px 12px;
  border: 1px solid #FFCC00; /* Adjust border color */
  color: black; /* Text color */
}

.page-link:hover {
  background-color: #FFCC00;
  color: white; /* Change text color on hover */
}

.disabled .page-link {
  background-color: white;
  color: grey; /* Color for disabled links */
}
</style>
