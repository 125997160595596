import IntermediateStoreService from '../services/IntermediateStoreService';

export default {
    async getAllStores() {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.getAllStores(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    },
    async getAllCount() {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.getAllCount(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    }, async getAllStatese(city) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.getAllStatese(token, city);
            return response.data;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    },

    async getAddress(long, lat) {
        try {

            const response = await IntermediateStoreService.getAddress(long, lat);
            return response.data;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    },
    async getAllStates() {
        try {

            const response = await IntermediateStoreService.getAllStates();
            return response.data;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    },

    async getAllCities(state) {
        try {

            const response = await IntermediateStoreService.getAllCities(state);
            return response.data;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    },

    async getAllNig(city) {
        try {

            const response = await IntermediateStoreService.getAllNig(city);
            return response.data;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    },
    async getStoreDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.getStoreDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    }, async getStoreOrderDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.getStoreOrderDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },

    async addStore(name, email, cost, description, numbers, password, address, selectedFileCom, selectedFileLis, seal_image, userName, storeImage, store_type, store_category, location_url) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.addStore(token, name, email, cost, description, numbers, password, address, selectedFileCom, selectedFileLis, seal_image, userName, storeImage, store_type, store_category, location_url);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async deleteStore(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.deleteStore(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },

    async getReport(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.getReport(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store report :', error.message);
            throw error;
        }
    },
    async blockStore(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.blockStore(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },

    async searchStore(name) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.searchStores(token, name);
            return response.data;
        } catch (error) {
            console.error('Error fetching store search :', error.message);
            throw error;
        }
    },


    async sendStore(state, city, neighborhood, cost) {
        try {
            const token = localStorage.getItem('token');
            const response = await IntermediateStoreService.sendStore(token, state, city, neighborhood, cost);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },

    async sortStores(costs, sales, created) {
        try {
            const token = localStorage.getItem('token');

            const response = await IntermediateStoreService.sortStores(token, costs, sales, created);
            return response.data;
        } catch (error) {
            console.error('Error searching products :', error.message);
            throw error;
        }
    },

};
