<template>
  <div class="background" :class="{ 'popup--back-open': showGroupPopUp }">
    <div class="blur"></div>
  </div>

  <div class="popup" :class="{ 'popup-open': showGroupPopUp }">
    <div class="expanded-row">
      <div class="dialog-title"></div>
      <div @click="closePopup" class="icon" v-html="closeIcon"></div>
    </div>
    <div class="popup-container">
      <div class="popup-content">
        <div class="popup-text">
          <div class="title">هل انت متأكد من أنك ترغب بتأكيد هذا الطلب  ؟</div>
        </div>
        <div class="popup-buttons">
          <button class="btn-cancel" @click="closePopup"><h3>لا</h3></button>
          <button class="btn-ok" v-if="!isLoading" @click="sendDeleteRequest"><h3>نعم</h3></button>
          <div v-if="isLoading" class="loader-container">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import {CloseIcon, SMS, UploadIcon, WhatsApp} from "@/util/constants";
import OrdersViewModel from "@/features/orders/viewmodels/OrdersViewModel";

export default {
  name: 'ConfirmEmployeeAddPopUp',
  props: {
    name: String,
    id: Number,
    isCat: Boolean

  },
  emits: ['close-popup'],
  setup() {
  },
  data() {
    return {
      groupName: '',
      showGroupPopUp: false,
      selectedImage: null,
      isLoading: false,
      closeIcon: CloseIcon,
      uploadIcon: UploadIcon,
      WhatsApp: WhatsApp,
      selectedFileName: null,
    };
  },
  methods: {
    SMS() {
      return SMS
    },
    async sendDeleteRequest() {
      try {
        this.isLoading = true;

       await OrdersViewModel.confirmEmployeeOrder(this.id);
        this.$router.push({name: 'WalletOrders'});
        this.isLoading = false;

        this.closePopup()

      } catch (error) {
        console.error("Error delete:", error.message);
        this.isLoading = false;
      }
    },


    openPopup() {
      this.showGroupPopUp = true;
    },
    closePopup() {
      this.$emit('close-popup');
      this.groupName = '';
      this.isLoading = false;
      this.selectedImage = null;
      this.showGroupPopUp = false;
    },
  },
};
</script>
<style scoped src="../presentation/styles/PopUp.css">


</style>
