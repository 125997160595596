import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const ReservationsEndPoint = 'stores/order';
const DetailsEndPoint = 'stores/order/'
const ConfirmEndPoint = 'stores/accept/order/'
const RejectEndPoint = 'stores/delete/order/'

class OrdersService {


    static async getAllReservations(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${ReservationsEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }


    static async getOrderDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.get(`${BaseUrl}${DetailsEndPoint}${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async getWalletOrderDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.get(`${BaseUrl}wallet/${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async getEmployeeOrderDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.get(`${BaseUrl}store/employees/orders/${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
    static async getUpdateOrderDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.get(`${BaseUrl}store/update/request/${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
    static async getProblemsOrderDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.get(`${BaseUrl}receiving/problems/${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async getAllWalletOrders(token, type, status) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-Paginate': 1,
                'X-Per-Page': 2,
            };

            let a, b, an, que = ''
            if (type !== '' && type !== undefined) {
                a = `operation_type=${type}`
                que = '?'
            }
            if (status !== '' && status !== undefined) {
                b = `status=${status}`
                if (que === '' || status === undefined) {
                    que = '?'
                } else {

                    an = '&'
                }
            }
            if (an === undefined) {
                an = ''
            }
            if (a === undefined) {
                a = ''
            }
            if (b === undefined) {
                b = ''
            }
            if (que === undefined) {
                que = ''
            }

            const response = await axios.get(`${BaseUrl}wallet/orders${que}${a}${an}${b}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async getAllUpdateStoreOrders(token, status) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-Paginate': 1,
                'X-Per-Page': 2,
            };

            let a, b, an, que = ''

            if (status !== '' && status !== undefined) {
                b = `status=${status}`
                if (que === '' || status === undefined) {
                    que = '?'
                }
            }
            if (an === undefined) {
                an = ''
            }
            if (a === undefined) {
                a = ''
            }
            if (b === undefined) {
                b = ''
            }
            if (que === undefined) {
                que = ''
            }

            const response = await axios.get(`${BaseUrl}store/update/request${que}${b}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }


    static async getEmployeeOrders(token) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-Paginate': 1,
                'X-Per-Page': 8,
            };


            const response = await axios.get(`${BaseUrl}store/employees/orders`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async getProblemsOrders(token) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-Paginate': 1,
                'X-Per-Page': 8,
            };


            const response = await axios.get(`${BaseUrl}receiving/problems`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
    static async getProblemsProductsOrders(token) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-Paginate': 1,
                'X-Per-Page': 8,
            };


            const response = await axios.get(`${BaseUrl}problems`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async confirmOrder(token, id, price) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const formData = new FormData();
            formData.append('cost', price);
            console.log(`id is ${id}`)

            const response = await axios.post(`${BaseUrl}${ConfirmEndPoint}${id}`, formData, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async confirmWalletOrder(token, id,) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };


            const response = await axios.patch(`${BaseUrl}wallet/order/confirm/${id}`, null, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async confirmEmployeeOrder(token, id,) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };


            const response = await axios.post(`${BaseUrl}store/employees/orders/accept/${id}`, null, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
    static async confirmUpdateStoreOrder(token, id,) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };


            const response = await axios.post(`${BaseUrl}store/update/request/accept/${id}`, null, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async rejectOrder(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.delete(`${BaseUrl}${RejectEndPoint}${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async rejectWalletOrder(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.patch(`${BaseUrl}wallet/order/reject/${id}`, null, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

    static async rejectEmployeeOrder(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.post(`${BaseUrl}store/employees/orders/reject/${id}`, null, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
    static async rejectStoreUpdateOrder(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.post(`${BaseUrl}store/update/request/reject/${id}`, null, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
}


export default OrdersService;
