<template>
  <div class="page-scrollable">
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-if="!isLoading">
      <div class="route-title">طلبات المحفظة</div>
      <div class="orders-page">
        <div class="order-types row" style="width: 100vw; ">
          <div class="col-lg-6 mb-4" v-for="type in orderTypes" :key="type.id">

            <Card class="col-12" :title="type.title" :description="type.description" @click="navigateTo(type)"/>
          </div>
        </div>


        <div style="margin: 10px; height:1px; background-color: rgb(0,0,0,0.2) "></div>
        <div style="margin: 25px "></div>
        <h3 class="route-title1">طلبات السحب</h3>
        <div style="margin: 20px "></div>
        <div style="width: 76vw;">
          <WalletTable :type="'withdrawal'" />
        </div>
<!--        <div class="custom-grid">-->
<!--          <intermidiate-shop-card class="item" v-for="(item, index) in productsItems" :key="index" :item="item"-->
<!--                                  @click="openDetails1(item.id)"/>-->

<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/features/orders/components/Card";
import OrdersViewModel from "@/features/orders/viewmodels/OrdersViewModel";
import WalletTable from "@/features/orders/components/WalletTable";
// import IntermidiateShopCard from "@/features/intermediate_store/presentation/components/IntermidiateShopCard";

export default {
  name: "WalletMainOrders",
  components: {
    WalletTable,
    // IntermidiateShopCard,
    Card,
  },
  methods: {
    navigateTo(type) {
      if (type.title === 'طلبات الدفع') {
        this.$router.push({name: 'WalletPaidOrders'});
      }   if (type.title === 'طلبات الايداع') {
        this.$router.push({name: 'WalletDepositOrders'});
      }
    },
    async fetchProductsData() {

      try {
        const data = await OrdersViewModel.getAllReservations();
        console.log(data)
        this.productsItems = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
  },
  created() {
    this.fetchProductsData()
  },
  data() {
    return {
      isLoading: false, // Add this if you want to control loading state
      orderTypes: [
        {id: 1, title: 'طلبات الدفع', description: 'استعراض طلبات الدفع الواردة الى النظام'},
        {id: 2, title: 'طلبات الايداع', description: 'استعراض طلبات الايداع الواردة الى النظام'},


      ],
      productsItems: [],
    };
  },
};
</script>

<style scoped>
.page-scrollable {
  padding: 20px;
}

.route-title {
  color: black;
  font-weight: 600;
  font-size: 20px;
  margin: 2px 0px 10px 0px;
  text-align: right;
  z-index: 1;
  flex: 2;
}

.route-title1 {
  color: black;
  font-size: 18px;
  margin: 2px 0px 10px 0px;
  text-align: right;
  z-index: 1;
  flex: 2;
}


/* Order Types Expanded Cards */
.order-types {
  display: flex;
  flex-wrap: wrap;
}

.expanded-card {
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.item {
  width: 300px;
}

.custom-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr); /* Default 1 item per row */
}

@media (min-width: 576px) {
  /* Small screens (>=576px): 2 items per row */
  .custom-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  /* Medium screens (>=768px): 3 items per row */
  .custom-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  /* Large screens (>=992px): 4 items per row */
  .custom-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.custom-grid-item {
  background: #fff;
  border-radius: 8px;
  padding: 15px;
}

/* Product Requests Grid */
.product-requests {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-requests .col-12 {
  display: flex;
  justify-content: center;
}

/* General layout and spacing */
.orders-page {
  padding: 10px;
}

.mb-4 {
  margin-bottom: 1.5rem;
}
</style>
