import OrdersService from '../services/OrdersService';

export default {
    async getAllReservations() {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getAllReservations(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async getAllWalletOrders(type, status) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getAllWalletOrders(token, type, status);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async getAllUpdateStoreOrders( status) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getAllUpdateStoreOrders(token, status);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async getEmployeeOrders() {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getEmployeeOrders(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    }, async getProblemsOrders() {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getProblemsOrders(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },async getProblemsProductsOrders() {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getProblemsProductsOrders(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },

    async getOrderDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getOrderDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    }, async getWalletOrderDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getWalletOrderDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    }, async getEmployeeOrderDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getEmployeeOrderDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    }, async getUpdateOrderDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getUpdateOrderDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },async getProblemsOrderDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getProblemsOrderDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async addNote(id, message) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.addNotes(token, id, message);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async confirmOrder(id, price) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.confirmOrder(token, id, price);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async rejectOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.rejectOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    }, async confirmWalletOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.confirmWalletOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    }, async confirmEmployeeOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.confirmEmployeeOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    }, async confirmProblemsOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.confirmEmployeeOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    }, async confirmUpdateStoreOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.confirmUpdateStoreOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async rejectWalletOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.rejectWalletOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async rejectEmployeeOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.rejectEmployeeOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },    async rejectStoreUpdateOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.rejectStoreUpdateOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
};
