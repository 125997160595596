import PublicPoliciesService from '../services/PublicPoliciesService';

export default {

    async getAllPolicies() {
        try {
            const token = localStorage.getItem('token');

            const response = await PublicPoliciesService.fetchPolicies(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching policies :', error.message);
            throw error;
        }
    },

    async updateAllPolicies(updateValue,number) {
        try {
            const token = localStorage.getItem('token');
            const adminId = localStorage.getItem('adminId');

            const response = await PublicPoliciesService.updatePolicies(token,adminId,updateValue,number);
            return response.data;
        } catch (error) {
            console.error('Error post policies :', error.message);
            throw error;
        }
    }, async updateAllPolicies2(links) {
        try {
            const token = localStorage.getItem('token');
            const adminId = localStorage.getItem('adminId');

            const response = await PublicPoliciesService.updatePolicies2(token,adminId,links);
            return response.data;
        } catch (error) {
            console.error('Error post policies :', error.message);
            throw error;
        }
    },
};
