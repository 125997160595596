<template>
  <div class="page-scrollable">
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-if="!isLoading">
      <div>
        <div class="row">
          <div>
            <div class="route-title">
              طلبات تعديل بيانات المتاجر
            </div>
          </div>
        </div>
      </div>
    </div>
    <StoreEditTable/>

    <!--    </div>-->
    <confirm-pop-up v-if="showPopup" ref="showDeletePopupRef" @close-popup="closePopUp1"
                    :id="this.$route.params.id"/>
    <reject-pop-up v-if="showPopup2" ref="showDelete2PopupRef" @close-popup="closePopUp2"
                   :id="this.$route.params.id"/>
    <add-price-pop-up v-if="showPopup3" ref="showDelete3PopupRef" @close-popup="closePopUp3"
                      :id="this.$route.params.id"/>
  </div>
</template>
<script>

import {
  CheckIcon,
  EmailIcon, ImageUrl,
  LocationIcon,
  PDFIcon2,
  PhoneIcon,

} from "@/util/constants";

import {format} from "date-fns";
import ConfirmPopUp from "@/features/orders/components/ConfirmPopUp.vue";
import RejectPopUp from "@/features/orders/components/RejectPopUp.vue";
import AddPricePopUp from "@/features/orders/components/AddPricePopUp.vue";
import StoreEditTable from "@/features/orders/components/StoreEditTable";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "StoreEditsOrders",
  components: {StoreEditTable, AddPricePopUp, RejectPopUp, ConfirmPopUp},
  data() {
    return {
      isReceiptActive: false,
      isInvoiceActive: true,
      id: null,
      name: '',
      phone_number: '',
      email: '',
      cost: null,
      lat: null,
      log: null,
      store_image: null,
      national_number: '',
      commercial_register: '',
      license: '',
      tax_number: '',
      commercial_register_image: null,
      tax_assignment_certificate_image: null,
      license_image: null,
      userImage: '',
      category: '',
      description: '',
      age: '',
      store_status: '',
      username: '',
      wight: null,
      carat: '',
      country: '',
      state: '',
      city: '',
      price_after_discount: null,
      neighborhood: '',
      street: '',
      showPopup: false,
      showPopup2: false,
      showPopup3: false,
      currentGoldPrice: null,
      profit: null,
      addition: null,
      manufacture: '',
      serviceRate: null,
      price: 0,
      gram: 0,
      note: '',
      rating: 4,
      isLoading2: false,
      rating1: 2,
      items2: [
        {title: 'نص البلاغ 1', username: 'اسم المستخدم 1', date: '20 \\ 10 \\ 2023'},
        {title: 'نص البلاغ 2', username: 'اسم المستخدم 2', date: '21 \\ 10 \\ 2023'},
        {title: 'نص البلاغ 1', username: 'اسم المستخدم 1', date: '20 \\ 10 \\ 2023'},
        {title: 'نص البلاغ 2', username: 'اسم المستخدم 2', date: '21 \\ 10 \\ 2023'},
      ],
      items: [],
      items3: [],
      billImage: null,
      images: [],
      buyer: null,
      buyerRate: null,
      numbers: [],
      isLoading: false,
      currentButtons: [
        'رفض الطلب',
        'قبول الطلب',
      ],

    };
  },
  computed: {
    backgroundImage() {
      return (index) => `url(''../../../../assets/images/${index}.png'')`;
    }
  },
  created() {
  },
  methods: {
    closePopUp1() {
      this.showPopup = false;
    }, closePopUp2() {
      this.showPopup2 = false;
    },
    closePopUp3() {
      this.showPopup3 = false;
    },
    openPopUp1(index) {
      if (index == 1) {
        console.log('func 11111')
        this.showPopup3 = true;
        if (this.$refs.showDelete3PopupRef && this.$refs.showDelete3PopupRef.openPopup) {
          this.$refs.showDelete3PopupRef.openPopup();

        }
      } else {
        console.log('func 11111')
        this.showPopup2 = true;
        if (this.$refs.showDelete2PopupRef && this.$refs.showDelete2PopupRef.openPopup) {
          this.$refs.showDelete2PopupRef.openPopup();

        }
      }


    }
    ,
    async onPress() {
      // try {
      //   const id = this.$route.params.id;
      //   this.isLoading2 = true
      //   const data = await ReservationsViewModel.addNote(id, this.note);
      //   console.log(data)
      //   this.note = ''
      //   this.isLoading2 = false
      //
      // } catch (error) {
      //   console.error("Error fetching statistics:", error.message);
      //   this.isLoading2 = false;
      //
      // }
    },
    toggleReceipt() {
      this.isReceiptActive = !this.isReceiptActive;
      if (this.isReceiptActive) {
        this.isInvoiceActive = false;
      }
    },
    toggleInvoice() {
      this.isInvoiceActive = !this.isInvoiceActive;
      if (this.isInvoiceActive) {
        this.isReceiptActive = false;
      }
    },
    ImageUrl() {

      return ImageUrl + this.license_image
    },
    ImageUrl1() {

      return ImageUrl + this.commercial_register_image
    }, ImageUrl3() {

      return ImageUrl + this.tax_assignment_certificate_image
    },
    openShareOptions() {
      let message = encodeURIComponent("Your message here");

      // Construct the WhatsApp share URL
      let url = `http://web.whatsapp://send?text=${message}`;

      // Open the share URL
      window.location.href = url;
      console.log('Share icon clicked! Implement your logic here.');
    },

    formattedDateTime(item) {
      const date = new Date(item);

      let hours = date.getHours();
      let minutes = date.getMinutes();
      hours = hours.toString().padStart(2, '0');
      minutes = minutes.toString().padStart(2, '0');

      const formattedTime = `${hours}:${minutes}`;

      const formattedDate = format(date, "dd/MM/yyyy");

      return `${formattedTime} ${formattedDate}`;
      // const date = new Date(item);
      // const formattedDate = format(date, "dd/MM/yyyy");
      //
      // let hours = date.getHours();
      // let minutes = date.getMinutes();
      // let ampm = hours >= 12 ? 'PM' : 'AM';
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      //
      // const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} `;
      //
      // return ` ${formattedDate} ${formattedTime} ${ampm}`;
    },
    PDFIcon() {
      return PDFIcon2
    },

    EmailIcon() {
      return EmailIcon
    },
    PhoneIcon() {
      return PhoneIcon
    },
    CheckIcon() {
      return CheckIcon
    },
    LocationIcon() {
      return LocationIcon
    }


  },
}
</script>
<style scoped src="../styles/ProductDetails.css">

</style>