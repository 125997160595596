// Import axios and BaseUrl from constants file
import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const PoliciesEndPoint = 'policies';

class PublicPoliciesService {
    static async fetchPolicies(token) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.get(`${BaseUrl}${PoliciesEndPoint}`, {headers});
            return response.data;
        } catch (error) {
            console.error('Error fetching policies:', error);
            throw error;
        }
    }

    static async updatePolicies(token, adminId, updateValue,number) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const formData = new FormData();
            formData.append('content[0]', updateValue);
            const response = await axios.post(`${BaseUrl}${PoliciesEndPoint}/${number}`, formData, {headers});
            return response.data;
        } catch (error) {
            console.error('Error updating policies:', error);
            throw error;
        }
    }
    static async updatePolicies2(token, adminId, links) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const formData = new FormData();
            links.forEach((link, index) => {
                formData.append(`content[${index}]`, link);
            });
            const response = await axios.post(`${BaseUrl}${PoliciesEndPoint}/14`, formData, {headers});
            return response.data;
        } catch (error) {
            console.error('Error updating policies:', error);
            throw error;
        }
    }
}

export default PublicPoliciesService;
